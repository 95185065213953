<template>
  <div class="card" style="width: 100%;">
    <img :src="'/astpos/' + id + '.png'" class="card-img-top" :alt="id">
    <div class="card-body">
      <h5 class="card-title">Armorstand Nr. {{id}}</h5>
    </div>
  </div>

</template>
<script>
export default {
  name: 'AstPositionCard',
  props: ['id']
}
</script>
<style>
</style>
