<template>
  <div class="container pb-3">
    <h1>Armorstand Positionen</h1>
    <div class="row mb-3">
      <div class="col-lg-4 col-md-6 col-sm-12" v-for="n in 31" v-bind:key="n">
        <AstPositionCard class="mb-4" :id="n"></AstPositionCard>
      </div>
    </div>
  </div>
</template>

<script>
import AstPositionCard from "@/components/AstPositionCard";

export default {
  name: "Adventskalender",
  components: {
    AstPositionCard
  },
}
</script>

<style scoped>
@media (prefers-color-scheme: dark)
.accordion-button:not(.collapsed) {
  color: #121212;
  background-color: orange;
  box-shadow: inset 0 -1px 0 rgb(250 250 250 / 13%);
}

.accordion-button:not(.collapsed) {
  color: #313131;
  background-color: orange;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.jumpRef {
  color: var(--bs-body-color);;
}

@media (prefers-color-scheme: dark) {
  .jumpRef {
    color: white;
  }
}

.jumpRef:hover {
  color: orange;
}

.custom-li::marker {
  content: attr(customContent) " ";
}

.tutorial-image {
  margin-bottom: .5rem;
}

.topic {
  margin-top: 2rem;
  text-decoration: underline;
  font-weight: bold;
}

.copy:hover {
  color: grey;
  cursor: pointer;
}

.marked-warning {
  color: orangered;
}

.marked-notice {
  color: yellow;
}

.marked {
  color: coral;
}

.player {
  color: #AAAAAA;
}

.pro {
  color: #FFAA00;
}

.mvp {
  color: #55FF55;
}

.expert {
  color: #55FFFF;
}

.admin {
  color: #0000AA;
}

.dev {
  color: #00AA00;
}

.mod {
  color: #AA0000;
}

.sup {
  color: #FFFF55;
}
</style>
